import React, { Component } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

export default class ProjctSingle extends Component {
  render() {
    const { data } = this.props;

    return (
      <Layout>
        <SEO 
          title={data.wordpressWpProject.title} 
          keywords={[`gatsby`, `application`, `react`]} />

        <section className="post__wrapper">
          
          <div className="post__navigation">
            <div className="wp-block-button btn btn-primary">
              <a className="wp-block-button__link btn-small" href="/work">Back to my Work list</a>
            </div>
          </div>

          <h1 className="post__title">{data.wordpressWpProject.title}</h1>

          <div 
            className="post__content" 
            dangerouslySetInnerHTML={{ __html: data.wordpressWpProject.content }} />

          <div className="post__navigation">
            <div className="wp-block-button btn btn-primary">
              <a className="wp-block-button__link btn-small" href="/work">Back to Work List</a>
            </div>
          </div>

        </section>
      </Layout>
    )
  }
}

export const query = graphql`
  query ProjectQuery($slug: String!) {
    wordpressWpProject(slug: {eq: $slug}) {
      slug
      title
      content
    }
  }
`
